* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
}

body h2 {
  color: #fd7a00;
}

header {
  background: #fd7a00ce;
  color: #fff;
  width: 100%;
  top: 0;
  z-index: 100;
}

.main-nav ul {
  justify-content: space-between;
}

.nav-right ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  align-items: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

nav ul li a:hover {
  color: #ff7f50;
}

.site-icon {
  width: 40px;
  height: auto;
}

#hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.hero-image {
  flex: 1;
  height: 100%;
  width: 100%;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section {
  padding: 60px 20px;
  text-align: center;
}

#about, #info p {
  color: #333;
}

#contact {
  color: #333;
}

#contact a {
  color: #ff7f50;
  text-decoration: none;
}

#contact a:hover {
  text-decoration: underline;
}

footer {
  text-align: center;
  padding: 20px;
  background: #fd7a00;
  color: #fff;
}

.whatsapp-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.whatsapp-button img {
  width: 60px;
  height: 60px;
}
